import React, { Component } from 'react';
import Aux from '../components/aux/aux';
import BatchDetails from '../containers/produce_tracking/batch_details';
import ProduceConfig from '../containers/produce_tracking/produce_config';
import GenerateQr from '../containers/produce_tracking/generate_qr';

class ProduceTracking extends Component {

  state = {
    currentContainer: '',
    cssLabelBatchDetails: 'SecondHeaderNonActive',
    cssLabelQrCode: 'SecondHeaderNonActive',
    cssLabelConfiguration: 'SecondHeaderNonActive'
  }

  static getDerivedStateFromProps(props, state) {
    if (state.currentContainer === '') {
      return { currentContainer: 'batchdetails', cssLabelBatchDetails: 'SecondHeaderActive' };
    }
    return <div/>
  }

  setContainers = (pageName, cssLabel) => {
    this.setState({ cssLabelBatchDetails: 'SecondHeaderNonActive', 
      cssLabelQrCode: 'SecondHeaderNonActive',
      cssLabelConfiguration: 'SecondHeaderNonActive'
    });

    this.setState({ currentContainer: pageName, [cssLabel]: 'SecondHeaderActive' });
  }

  render() {
    let containers = null;
    switch (this.state.currentContainer) {
      case 'batchdetails':
        containers = <BatchDetails />
        break;

      case 'configuration':
        containers = <ProduceConfig />
        break;

      case 'qrcode':
        containers = <GenerateQr />
        break;
        
      default: 
        containers = null;
    }

    return (
      <Aux>
        <div className="LayoutSecondHeader">
          <div style={{ borderTop: '0.1em solid green', width: '100%', margin: '-3px 0px 0px 216px', paddingTop: '23px' }}>
            <label className={this.state.cssLabelBatchDetails} style={{ cursor: 'pointer' }}
              onClick={() => this.setContainers('batchdetails', 'cssLabelBatchDetails')}>
              Batch Details
            </label>
            <label className={this.state.cssLabelQrCode} style={{ cursor: 'pointer' }}
              onClick={() => this.setContainers('qrcode', 'cssLabelQrCode')}>
              QR Code
            </label>
            <label className={this.state.cssLabelConfiguration} style={{ cursor: 'pointer' }}
              onClick={() => this.setContainers('configuration', 'cssLabelConfiguration')}>
              Configuration
            </label>
          </div>
        </div>
        <div style={{ marginTop: '-80px' }}>
          {containers}
        </div>
      </Aux>
    );
  }
}

export default ProduceTracking;
