import firebase from 'firebase/app';
import 'firebase/auth';
import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER_SUCCESS
} from './types';

export const loginUser = (email, password) => dispatch => {
  firebase.auth().signInWithEmailAndPassword(email, password)
    .then(newLogin => loginUserSuccess(dispatch, newLogin))
    .catch(error => loginUserFail(dispatch, error));
};

const loginUserSuccess = (dispatch, user) => {
  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: user
  })
};

const loginUserFail = (dispatch, error) => {
  dispatch({
    type: LOGIN_USER_FAIL,
    payload: error
  })
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER_SUCCESS
  }
};