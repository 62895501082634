import React, { Component } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

class GeneratePdf extends Component {

  state = {
    textData: []
  }

  static getDerivedStateFromProps(props, state) {
    return { textData: props.data };
  }

  render() {
    
    return (
      <div>
        <PDFViewer>
          <Document>
            <Page size="A4" style={{ margin: '20px' }}>
              <View>
                <Text>{this.props.text}</Text>
                {this.state.textData.map((value, key) => {
                  return (
                    <Text key={key}>
                      {value.name + '\n\n'}
                    </Text>
                  )
                })}
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

export default GeneratePdf;
