import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  message: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, status: 'login', message: '' };

    case LOGIN_USER_FAIL:
      return { ...state, message: action.payload, status: 'failed' };

    case LOGOUT_USER_SUCCESS:
      return { ...state, user: null };
      
    default:
      return state;
  }
};
