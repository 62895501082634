import React, { Component, Fragment } from 'react';
import IconButton from '../common/icon_button';
import { Button, Drawer } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import firebase from 'firebase/app';
import 'firebase/auth';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import MenuList from '../header/menu_list';
import WebLogo from '../../images/weblogo_white.png';

class AppHeader extends Component {
  
  constructor(props) {
    super(props);

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ authText: 'Logout', menu_disable: false });
      } else {
        this.setState({ authText: 'Login', menu_disable: true }, this.props.history.push("/auth"));
      }
    })
  }

  state = {
    authText: 'Login',
    left: false,
    menu_disable: false
  }

  static getDerivedStateFromProps(props, state) {

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        state.authText = 'Logout';
        state.menu_disable = false;
      } else {
        state.authText = 'Login';
        state.menu_disable = true;
      }
    })
  
    return (state.authText);
  }

  authPage = () => {
    firebase.auth().signOut().then(() => {
      this.props.logoutUser();
      this.props.history.push("/auth");
    })
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ [anchor]: open });
  };

  render() {
    return (
      <div>
        <div className="LayoutHeader">
          <Fragment key='left'>
            <Button onClick={this.toggleDrawer('left', true)} disabled={this.state.menu_disable}>
              <Menu style={{ color: 'white', fontSize: 30 }} />
            </Button>
            <Drawer anchor="left" open={this.state.left} onClose={this.toggleDrawer('left', false)}>
              <MenuList onClick={this.toggleDrawer('left', false)} onKeyDown={this.toggleDrawer('left', false)} />
            </Drawer>
          </Fragment>
          <div className="main-header-div">
            <div>
              <img src={WebLogo} alt="logo" />
            </div>
            <div className="main-header-div">
              <h1>360ism Management Console</h1>
              <IconButton title={this.state.authText} onClick={this.authPage} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, actions)(AppHeader));