import React, { Component } from 'react';
import { Grid, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import EnhancedTableHead from '../../components/table_common/enhanced_table_head';
import EnhancedTableToolbar from '../../components/table_common/enhanced_table_toolbar';

class OrderToFarm extends Component {
  
  state = {
    selected: [],
    rows: [
      { name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3},
      { name: 'Donut', calories: 305, fat: 3.7, carbs: 67, protein: 4.3},
      { name: 'Forzen yoghurt', calories: 305, fat: 3.7, carbs: 67, protein: 4.3}
    ],
    headCells: [
      { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
      { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
      { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
      { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
      { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
    ]
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const NewSelected = this.state.rows.map((n) => n.name);
      this.setState({ selected: NewSelected });
      return;
    }
    this.setState({ selected: [] });
  }

  handleClick = (event, name) =>  {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    console.log(selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  }  

  render() {
    const isSelected = (name) => this.state.selected.indexOf(name) !== -1;
    
    return (
        <Grid container style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <Grid item style={{ padding: '0px 15px', marginTop: '20px' }} xs={8}>
            <Card raised color="#fff" style={{ borderRadius: '8px' }}>
            <CardContent>
              <EnhancedTableToolbar numSelected={this.state.selected.length} title="Nutritions9"/>
              <TableContainer>
                <Table size='small'>
                  <EnhancedTableHead 
                    numSelected={this.state.selected.length}
                    onSelectAllClick={this.handleSelectAllClick}
                    rowCount={this.state.rows.length}
                    headCells={this.state.headCells}
                  />
                  <TableBody>
                    {this.state.rows.map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => this.handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox 
                              checked={isItemSelected} 
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            </Card>
          </Grid>
        </Grid>
    );
  }
}

export default OrderToFarm;
