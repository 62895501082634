import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Home, EventNote, Update, LabelOutlined } from '@material-ui/icons';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontSize: 11
  }
});

const MenuList = (props) => {
  return (
    <div style={{ marginTop: '100px', width: '270px' }} role="presentation" onClick={props.onClick} onKeyDown={props.onKeyDown}>
      <ThemeProvider theme={theme}>
      <List>
        <NavLink to="/home" className="DrawerMenu" activeClassName="ActiveDrawerMenu" >
          <ListItem button key="home" >
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </NavLink>
        <NavLink to="/orderingdistribution" className="DrawerMenu" activeClassName="ActiveDrawerMenu">
          <ListItem button key="eventnote">
            <ListItemIcon><EventNote /></ListItemIcon>
            <ListItemText primary="Ordering & Distribution" />
          </ListItem>
        </NavLink>
        <NavLink to="/producetracking" className="DrawerMenu" activeClassName="ActiveDrawerMenu">
          <ListItem button key="eventnote">
            <ListItemIcon><Update /></ListItemIcon>
            <ListItemText primary="Produce Tracking" />
          </ListItem>
        </NavLink>
        <NavLink to="/skumanagement" className="DrawerMenu" activeClassName="ActiveDrawerMenu">
          <ListItem button key="eventnote">
            <ListItemIcon><LabelOutlined /></ListItemIcon>
            <ListItemText primary="SKU Management" />
          </ListItem>
        </NavLink>
      </List>
      <Divider />
      </ThemeProvider>
    </div>
  );
}

export default MenuList;
