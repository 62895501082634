import _ from 'lodash';
import React, { Component } from 'react';
import { Grid, Card, CardContent, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, Typography, Button } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import Aux from '../../components/aux/aux';
import EnhancedTableToolbar from '../../components/table_common/enhanced_table_toolbar';
import EnhancedTableHead from '../../components/table_common/enhanced_table_head';
import QrCode from 'qrcode.react';

class GenerateQr extends Component {

  constructor(props) {
    super(props);
    this.loadDataFromDatabase();
  }

  state = {
    selected: [],
    qrcodes: [],
    rows: [],
    headCells: [
      { id: 'farmer', numeric: false, disablePadding: false, label: 'Farmer' },
      { id: 'produce', numeric: false, disablePadding: false, label: 'Produce' },
      { id: 'sowing_date', numeric: false, disablePadding: false, label: 'Sowing' },
      { id: 'transplant_date', numeric: false, disablePadding: false, label: 'Transplant' },
      { id: 'harvest_date', numeric: false, disablePadding: false, label: 'Harvest' },
      { id: 'spdeliver_date', numeric: false, disablePadding: false, label: 'Sort, Pack, Deliver' }
    ]
  }

  loadDataFromDatabase = async () => {
    let fiveAgo = moment().tz('Asia/Kuala_Lumpur').subtract(6, 'days').unix();
    let data = [];

    const db = firebase.firestore();
    let snapshot = await db.collection('produce_tracker').where('harvest', '>', fiveAgo)
      .orderBy('harvest', 'desc').get();

    snapshot.forEach(doc => {
      let tmp = doc.data();
      let incId = { ...tmp, id: doc.id };
      data.push(incId);
    })

    this.setState({ rows: data });
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const NewSelected = this.state.rows.map((n) => n.id);
      this.setState({ selected: NewSelected });
      return;
    }
    this.setState({ selected: [] });
  }

  handleClick = (event, name) =>  {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    console.log(selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  }  

  capture = () => {
    const { selected, rows } = this.state;
    let i = 0;
    let j = 0;

    for (i=0; i<selected.length; i++) {
      let canvas = document.getElementById(selected[i]);
      let d_url = canvas.toDataURL("image/png");
      let link = document.createElement('a');
      link.href = d_url;

      for (j=0; j<rows.length; j++) {
        if (rows[j].id === selected[i]) {
          link.download = moment.unix(rows[j].spdeliver).tz('Asia/Kuala_Lumpur').format('yyyyMMDD') + '-' + rows[j].farmer + '-' + rows[j].produce
          document.body.appendChild(link);
          link.click();
        }
      }
    }
  }

  generateQR = () => {
    const { selected, rows } = this.state;
    let i = 0;

    return _.map(selected, item => {
      for (i=0; i<rows.length; i++) {
        if (rows[i].id === item) {
          return (
            <div key={item + i} style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography key={i} variant='subtitle2'>{rows[i].farmer + '-' + rows[i].produce}</Typography>
              <Typography key={i + rows[i].produce} variant='subtitle2'>Delivery: {moment.unix(rows[i].spdeliver).tz('Asia/Kuala_Lumpur').format('DD-MM-yyyy')}</Typography>
              <QrCode key={item} value={item} size={128} id={item}
                style={{ margin: '10px 30px 20px 0px' }} />
            </div>
          );
        }
      }
    });
  }

  render() {
    const isSelected = (name) => this.state.selected.indexOf(name) !== -1;
    let l2CardHidden = 'hidden';
    if (this.state.selected.length > 0) {
      l2CardHidden = 'visible'
    }

    return (
      <Aux>
        <Grid container style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <Grid item style={{ padding: '0px 15px', marginTop: '20px' }} xs={8}>
            <Card raised color="#fff" style={{ borderRadius: '8px', width: '900px' }}>
              <CardContent style={{ padding: '30px 50px' }}>
                <EnhancedTableToolbar numSelected={this.state.selected.length} title="Generate QR Code" buttonHidden='hidden' />
                <TableContainer>
                  <Table size='small'>
                    <EnhancedTableHead
                      numSelected={this.state.selected.length}
                      onSelectAllClick={this.handleSelectAllClick}
                      rowCount={this.state.rows.length}
                      headCells={this.state.headCells}
                    />
                    <TableBody>
                      {this.state.rows.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell>{row.farmer}</TableCell>
                            <TableCell>{row.produce}</TableCell>
                            <TableCell>{moment.unix(row.sowing).tz('Asia/Kuala_Lumpur').format('DD-MM-yyyy')}</TableCell>
                            <TableCell>{moment.unix(row.transplant).tz('Asia/Kuala_Lumpur').format('DD-MM-yyyy')}</TableCell>
                            <TableCell>{moment.unix(row.harvest).tz('Asia/Kuala_Lumpur').format('DD-MM-yyyy')}</TableCell>
                            <TableCell>{moment.unix(row.spdeliver).tz('Asia/Kuala_Lumpur').format('DD-MM-yyyy')}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
            <Card raised color="#fff" style={{ borderRadius: '8px', width: '900px', marginTop: '30px', visibility: l2CardHidden }}>
              <CardContent style={{ padding: '30px 50px' }}>
                <Button variant='outlined' color='primary' onClick={this.capture}>Download QR Codes</Button>
                <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap', marginTop: '30px' }}>
                  {this.generateQR()}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Aux>
    );
  }
}

export default GenerateQr;
