import React from 'react';
import { Button } from '@material-ui/core';

const IconButton = (props) => {
  return (
    <Button variant="outlined" color="primary" size="small" onClick={props.onClick}>
      {props.title}
    </Button>
  );
}

export default IconButton;
