import React from 'react';
import { Toolbar, Typography, Tooltip, Button } from '@material-ui/core';

const EnchancedTableToolbar =  (props) => {

  const { numSelected, title, buttonTitle, buttonOnClick, buttonHidden } = props;

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div" style={{ marginRight: '30px' }}>
          {numSelected} selected
        </Typography>
      ) : (
          <Typography variant='subtitle2' id="tableTitle" component="div">
            {title}
          </Typography>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <Button variant='outlined' color='primary' onClick={buttonOnClick} style={{ visibility: buttonHidden }}>{buttonTitle}</Button>
        </Tooltip>
      ) : (
          null
        )}
    </Toolbar>
  );
}

export default EnchancedTableToolbar;
