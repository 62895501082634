import { withStyles, TextField } from '@material-ui/core';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#02b7a5',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#02b7a5',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#02b7a5',
      },
    },
  },
})(TextField);

export default CssTextField;
