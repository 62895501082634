import React, { Component } from 'react';
import  { Typography } from '@material-ui/core';
import CSVReader from 'react-csv-reader';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Aux from '../../components/aux/aux';

class SkuData extends Component {

  state = {
    textResult: '',
    textColor: 'black'
  }

  deleteCollection = async(db, collectionPath, batchSize) => {
    const collectionRef = db.collection(collectionPath);
    const query = collectionRef.orderBy('id').limit(batchSize);
  
    return new Promise((resolve, reject) => {
      this.deleteQueryBatch(db, query, resolve).catch(reject);
    });
  }
  
  deleteQueryBatch = async(db, query, resolve) => {
    const snapshot = await query.get();
  
    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }
  
    // Delete documents in a batch
    const batch = db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
  
    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
      this.deleteQueryBatch(db, query, resolve);
    });
  }

  handleFile = async (data) => {
    const db = firebase.firestore();
    // const settings = {timestampsInSnapshots: true};
    // db.settings(settings);

    await db.collection("sku_jaya").doc().delete();

    let i = 0;
    for (i=0; i<data.length; i++) {
      db.collection("sku_jaya").add({
        id: data[i].id,
        sku_no: data[i].sku_no,
        sku_name: data[i].sku_name
      }).then(
        this.setState({ textResult: 'Upload successful', textColor: 'green' })
      )
    }
  }

  updateSKU = async (data) => {
    const db = firebase.firestore();
    await this.deleteCollection(db, 'sku_jaya', 10);
    this.handleFile(data);
  }

  render() {
    
    const parseOptions = {
      header: true,
      skipEmptyLines: true
    }

    return (
      <Aux>
        <div className="auth-frame-div" style={{ marginTop: '30px' }}>
          <Typography variant="subtitle2" style={{ marginBottom: '5px' }}>Select SKU file to upload [csv file]</Typography>
          <CSVReader onFileLoaded={this.updateSKU} parserOptions={parseOptions} />
          <Typography variant="subtitle2" style={{ marginBottom: '5px', marginTop: '10px', color: this.state.textColor }}>{this.state.textResult}</Typography>
        </div>
      </Aux>
    );
  }
}

export default SkuData;
