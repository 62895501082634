import React, { Component } from 'react';
import { Typography, Button } from '@material-ui/core';
import XLSX from 'xlsx';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Aux from '../../components/aux/aux';
import GeneratePdf from '../../components/common/generate_pdf';

class SkuPrinting extends Component {

  state = {
    input_disable: false,
    total_sku: 0,
    sku_distribution_JB: [],
    sku_distribution_SGeo: [],
    sku_distribution_USJ: [],
    sku_distribution_JS: [],
    vegePackWithSKU_JB: [],
    vegeWeightWithSKU_JB: [],
    vegePackWithSKU_SGeo: [],
    vegeWeightWithSKU_SGeo: [],
    vegePackWithSKU_USJ: [],
    vegeWeightWithSKU_USJ: [],
    vegePackWithSKU_JS: [],
    vegeWeightWithSKU_JS: [],
    date_bangi: '',
    date_sunwaygeo: '',
    date_usj: '',
    date_sierramas: ''
  }

  read_sku = async () => {
    let i = 0;
    let arJB_pack = [];
    let arJB_weight = [];
    let arSGeo_pack = [];
    let arSGeo_weight = [];
    let arUSJ_pack = [];
    let arUSJ_weight = [];
    let arJS_pack = [];
    let arJS_weight = [];

    const db = firebase.firestore();
    this.setState({ sku_data: [] });

    const all_sku = db.collection('sku_jaya');
    const snapshot = await all_sku.get();

    snapshot.forEach(sku => {
      for (i=0; i<this.state.total_sku; i++) {
        let sku_no_db = parseInt(sku.data().sku_no);
        let sku_name_db = sku.data().sku_name;

        // Bangi
        if (sku_no_db === this.state.sku_distribution_JB[i]) {
          if (sku_name_db.includes('250G')) {
            arJB_pack.push({ name: sku_name_db });
          } else {
            arJB_weight.push({ name: sku_name_db });
          }
        } 

        // SGeo
        if (sku_no_db === this.state.sku_distribution_SGeo[i]) {
          if (sku_name_db.includes('250G')) {
            arSGeo_pack.push({ name: sku_name_db });
          } else {
            arSGeo_weight.push({ name: sku_name_db });
          }
        } 

        // USJ
        if (sku_no_db === this.state.sku_distribution_USJ[i]) {
          if (sku_name_db.includes('250G')) {
            arUSJ_pack.push({ name: sku_name_db });
          } else {
            arUSJ_weight.push({ name: sku_name_db });
          }
        } 

        // JS
        if (sku_no_db === this.state.sku_distribution_JS[i]) {
          if (sku_name_db.includes('250G')) {
            arJS_pack.push({ name: sku_name_db });
          } else {
            arJS_weight.push({ name: sku_name_db });
          }
        } 
      }
    })
    this.setState({ vegePackWithSKU_JB: arJB_pack, vegeWeightWithSKU_JB: arJB_weight,
      vegePackWithSKU_SGeo: arSGeo_pack, vegeWeightWithSKU_SGeo: arSGeo_weight,
      vegePackWithSKU_USJ: arUSJ_pack, vegeWeightWithSKU_USJ: arUSJ_weight,
      vegePackWithSKU_JS: arJS_pack, vegeWeightWithSKU_JS: arJS_weight
    });
  }

  handleFile = (e) => {
    let files = e.target.files, f = files[0];
    let reader = new FileReader();

    reader.onload = e => {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, {type: 'array'});

      let sku_count = workbook.Sheets['dist planning+actual del form'].C2.v;
      let date_bangi = workbook.Sheets['dist planning+actual del form'].I4.w;
      let date_sunwaygeo = workbook.Sheets['dist planning+actual del form'].K4.w;
      let date_usj = workbook.Sheets['dist planning+actual del form'].M4.w;
      let date_sierramas = workbook.Sheets['dist planning+actual del form'].O4.w;

      let start = 5;
      let i = 0;
      let RCW = ''
      let RCI = ''
      let RCK = ''
      let RCM = ''
      let RCO = ''
      let col_data = '';

      for (i = start; i < sku_count + start; i++) {
        RCW = 'W' + i;
        RCI = 'I' + i;
        RCK = 'K' + i;
        RCM = 'M' + i;
        RCO = 'O' + i;

        col_data = workbook.Sheets['dist planning+actual del form'][RCW].v;

        if (workbook.Sheets['dist planning+actual del form'][RCI].v !== 0) {
          this.state.sku_distribution_JB.push(col_data);
        }
        if (workbook.Sheets['dist planning+actual del form'][RCK].v !== 0) {
          this.state.sku_distribution_SGeo.push(col_data);
        }
        if (workbook.Sheets['dist planning+actual del form'][RCM].v !== 0) {
          this.state.sku_distribution_USJ.push(col_data);
        }
        if (workbook.Sheets['dist planning+actual del form'][RCO].v !== 0) {
          this.state.sku_distribution_JS.push(col_data);
        }
      }      
      this.setState({ total_sku: sku_count, date_bangi, date_sunwaygeo, date_usj, date_sierramas, input_disable: true });
    };
    reader.readAsArrayBuffer(f);
  }

  generateSKU_List = async (e) => {
    this.handleFile(e);
    await this.read_sku();
  }

  reloadPage = () => {
    window.location.reload(true);
  }

  render() {

    return (
      <Aux>
        <div className="auth-frame-div" style={{ marginTop: '30px' }}>
          <Typography variant="subtitle2" style={{ marginBottom: '10px' }}>Distribution list [excel file]</Typography>
          <input type="file" onChange={this.generateSKU_List} disabled={this.state.input_disable} />
          <Button variant="outlined" size="small" color="primary" onClick={this.reloadPage} style={{ marginTop: '15px' }}>Reload</Button>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography variant="caption">Bangi SKU List Price Tag</Typography>
            <GeneratePdf text={'Bangi price tag to print on ' + this.state.date_bangi + '\n\n'} data={this.state.vegePackWithSKU_JB} />
          </div>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="caption">Bangi SKU List Loose Items</Typography>
            <GeneratePdf text={'Bangi loose items on ' + this.state.date_bangi + '\n\n'} data={this.state.vegeWeightWithSKU_JB} />
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography variant="caption">Sunway Geo SKU List Price Tag</Typography>
            <GeneratePdf text={'Sunway Geo price tag to print on ' + this.state.date_sunwaygeo + '\n\n'} data={this.state.vegePackWithSKU_SGeo} />
          </div>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="caption">Sunway Geo SKU List Loose Items</Typography>
            <GeneratePdf text={'Sunway Geo loose items on ' + this.state.date_sunwaygeo + '\n\n'} data={this.state.vegeWeightWithSKU_SGeo} />
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography variant="caption">USJ SKU List Price Tag</Typography>
            <GeneratePdf text={'USJ price tag to print on ' + this.state.date_usj + '\n\n'} data={this.state.vegePackWithSKU_USJ} />
          </div>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="caption">USJ SKU List Loose Items</Typography>
            <GeneratePdf text={'USJ loose items on ' + this.state.date_usj + '\n\n'} data={this.state.vegeWeightWithSKU_USJ} />
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Typography variant="caption">Sierramas SKU List Price Tag</Typography>
            <GeneratePdf text={'Sierramas price tag to print on ' + this.state.date_sierramas + '\n\n'} data={this.state.vegePackWithSKU_JS} />
          </div>
          <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="caption">Sierramas SKU List Loose Items</Typography>
            <GeneratePdf text={'Sierramas loose items on ' + this.state.date_sierramas + '\n\n'} data={this.state.vegeWeightWithSKU_JS} />
          </div>
        </div>
      </Aux>
    );
  }
}

export default SkuPrinting;
