import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, FormControl } from '@material-ui/core';
import * as actions from '../actions';
import CssTextField from '../components/common/custom_textfield';
import IconButton from '../components/common/icon_button';

class Auth extends Component {

  state = {
    email: '',
    password: ''
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user) {
      props.history.push("/home");
    }
    return (<div/>);
  }

  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  authenticate = () => {
    const { email, password } = this.state;
    const { user } = this.props;

    if (!user) {
      this.props.loginUser(email, password);
    } 
  }

  render() {
    return (
      <div className="auth-frame-div" style={{ marginTop: '150px' }}>
        <Typography variant="subtitle1">Welcome to 360ism Management Console</Typography>
        <div>
          <FormControl style={{ margin: '40px 10px 10px 10px', width: '350px' }}>
            <CssTextField 
              id="email" 
              label="Email" 
              type="text" 
              value={this.state.email} 
              onChange={this.handleTextChange('email')}
              InputLabelProps={{ shrink: true }}/>
          </FormControl>
        </div>
        <div>
          <FormControl style={{ margin: '10px 10px 40px 10px', width: '350px' }}>
            <CssTextField
              id="password"
              label="Password"
              type="password"
              value={this.state.password}
              onChange={this.handleTextChange('password')}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </div>
        <IconButton title="Login" onClick={this.authenticate} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps, actions)(Auth);
