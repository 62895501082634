import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import thunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import firebase from 'firebase/app';

import reducers from './reducers';
import AppHeader from './components/header/app_header';
import Auth from './views/auth';
import Home from './views/home';
import SkuManagement from './views/sku_management';
import OrderingDistribution from './views/ordering_distribution';
import ProduceTracking from './views/produce_tracking';

const createStoreWithMiddleware = applyMiddleware(thunk, ReduxPromise)(createStore);

const config = {
  apiKey: 'AIzaSyAhM87kxc6Dq1xbUr0dPnF7fZTsWi1AsPg',
  authDomain: 'ism-3bae1.firebaseapp.com',
  databaseURL: 'https://ism-3bae1.firebaseio.com',
  projectId: 'ism-3bae1',
  storageBucket: 'ism-3bae1.appspot.com',
  // messagingSenderId: '1090932325898'
};
firebase.initializeApp(config);

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <BrowserRouter>
      <div>
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', zIndex: '9999' }}>
          <AppHeader />
        </div>
        <div>
          <Switch>
            <Route path="/auth" component={Auth} />
            <Route path="/home" component={Home} />
            <Route path="/skumanagement" component={SkuManagement} />
            <Route path="/orderingdistribution" component={OrderingDistribution} />
            <Route path="/producetracking" component={ProduceTracking} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  </Provider>
  , document.getElementById('root'));