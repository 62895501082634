import React, { Component } from 'react';
import { capitalize } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Grid, Card, CardContent, Typography, Button, TextField } from '@material-ui/core';
import Spinner from '../../components/common/spinner';
import LoadingModal from '../../components/common/loading_modal';
import Aux from '../../components/aux/aux';

class ProduceConfig extends Component {

  state = {
    loading: false,
    farmer: '',
    produce: '',
    price: '',
    remarks: '',
    remarksColor: 'green'
  }

  farmerChange = (event) => {
    this.setState({ farmer: event.target.value });
  }

  produceChange = (event) => {
    this.setState({ produce: event.target.value });
  }

  priceChange = (event) => {
    this.setState({ price: event.target.value });
  }

  saveDataToFirestore = async () => {
    const { farmer, produce, price } = this.state;

    if (farmer === '' || produce === '' || price === '') {
      this.setState({ remarks: 'All input has to be filled', remarksColor: 'red' });
      return;
    } 

    this.setState({ loading: true });

    const db = firebase.firestore();
    await db.collection('farmer_produce').where('farmer', '==', capitalize(farmer))
      .where('produce', '==', capitalize(produce))
      .get()
      .then((item) => {
        if (item.size === 0) {
          db.collection('farmer_produce').add({
            farmer: capitalize(farmer), 
            produce: capitalize(produce), 
            price: parseFloat(price)
          }).then(() => {
            this.setState({ remarks: 'New Farmer and Produce added', remarksColor: 'green', loading: false });
          })
        } else {
          this.setState({ remarks: 'Farmer and Produce exist in the system', remarksColor: 'red', loading: false });
        }
      })
  }

  render() {

    let loadingModal = null;
    if (this.state.loading) {
      loadingModal = (
        <div>
          <LoadingModal show={true}>
            <div>
              <Spinner />
            </div>
          </LoadingModal>
        </div>
      );
    }

    return (
      <Aux>
        {loadingModal}
        <Grid container style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <Grid item style={{ padding: '0px 15px', marginTop: '20px' }} xs={8}>
            <Card raised color="#fff" style={{ borderRadius: '8px', width: '550px', marginLeft: '150px' }}>
              <CardContent style={{ padding: '30px 50px' }}>
                <Typography variant="subtitle2" style={{ marginBottom: '30px' }}>Produce Configuration</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                  <TextField variant="outlined" label="Farmer" style={{ marginBottom: '15px' }} onChange={this.farmerChange} />
                  <TextField variant="outlined" label="Produce Name" style={{ marginBottom: '15px' }} onChange={this.produceChange} />
                  <TextField variant="outlined" label="Price (RM) /kg" style={{ marginBottom: '15px' }} onChange={this.priceChange} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Button variant="outlined" color="primary" onClick={this.saveDataToFirestore}>Save</Button>
                  <Typography variant="subtitle2" style={{ color: this.state.remarksColor, marginLeft: '30px' }}>{this.state.remarks}</Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Aux>
    );
  }
}

export default ProduceConfig;
