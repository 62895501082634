import React, { Component } from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Aux from '../components/aux/aux';
import TabContainer from '../components/common/tab_container';
import SkuPrinting from '../containers/sku_management/sku_printing';
import SkuData from '../containers/sku_management/sku_data';

class SkuManagement extends Component {

  state = {
    value: 0
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  render() {
    return (
      <Aux>
        <div style={{ marginTop: '150px' }}>
          <AppBar position="static" color="default" style={{ marginBottom: '30px' }}>
            <Tabs
              value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab style={{ fontSize: '14px', textTransform: 'capitalize' }} label="SKU Printing" />
              <Tab style={{ fontSize: '14px', textTransform: 'capitalize' }} label="SKU Data" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && <TabContainer>
            <SkuPrinting />
          </TabContainer>}
          {this.state.value === 1 && <TabContainer>
            <SkuData />
          </TabContainer>}
        </div>
      </Aux>
    );
  }
}

export default SkuManagement;
