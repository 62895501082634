import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const AlertDialog = (props) => {

  return (
    <div>
      <Dialog 
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClickConfirm} color="primary" variant="outlined">Confirm</Button>
          <Button onClick={props.onClickCancel} color="primary" variant="outlined">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
