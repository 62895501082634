import React, { Component } from 'react';
import Aux from '../components/aux/aux';
import OrderToFarm from '../containers/ordering_distribution/order_to_farm';

class OrderingDistribution extends Component {

  state = {
    currentContainer: '',
    cssLabelOrderToFarm: 'SecondHeaderNonActive',
    cssLabelDistribution: 'SecondHeaderNonActive',
    cssLabelConfiguration: 'SecondHeaderNonActive'
  }

  static getDerivedStateFromProps(props, state) {
    if (state.currentContainer === '') {
      return { currentContainer: 'ordertofarm', cssLabelOrderToFarm: 'SecondHeaderActive' };
    }
    return <div/>
  }

  setContainers = (pageName, cssLabel) => {
    this.setState({ cssLabelOrderToFarm: 'SecondHeaderNonActive', 
      cssLabelDistribution: 'SecondHeaderNonActive',
      cssLabelConfiguration: 'SecondHeaderNonActive'
    });

    this.setState({ currentContainer: pageName, [cssLabel]: 'SecondHeaderActive' });
  }

  render() {  
    let containers = null;
    switch (this.state.currentContainer) {
      case 'ordertofarm':
        containers = <OrderToFarm />
        break;

      default: 
        containers = null;
    }

    return (
      <Aux>
        <div className="LayoutSecondHeader">
          <div style={{ borderTop: '0.1em solid green', width: '100%', margin: '-3px 0px 0px 216px', paddingTop: '23px' }}>
            <label className={this.state.cssLabelOrderToFarm} style={{ cursor: 'pointer' }}
              onClick={() => this.setContainers('ordertofarm', 'cssLabelOrderToFarm')}>
              Order to Farm
            </label>
            <label className={this.state.cssLabelDistribution} style={{ cursor: 'pointer' }}
              onClick={() => this.setContainers('distribution', 'cssLabelDistribution')}>
              Distribution
            </label>
            <label className={this.state.cssLabelConfiguration} style={{ cursor: 'pointer' }}
              onClick={() => this.setContainers('configuration', 'cssLabelConfiguration')}>
              Configuration
            </label>
          </div>
        </div>
        <div style={{ marginTop: '-80px' }}>
          {/* {containers} */}
        </div>
      </Aux>
    );
  }
}

export default OrderingDistribution;
