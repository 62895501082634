import _ from 'lodash';
import React, { Component } from 'react';
import { capitalize } from 'lodash';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import { Grid, Card, CardContent, Typography, TextField, Button, FormControl, 
  InputLabel, Select, MenuItem } from '@material-ui/core';
import moment from 'moment-timezone';
import Aux from '../../components/aux/aux';
import AlertDialog from '../../components/common/alert_dialog';

class BatchDetails extends Component {

  constructor(props) {
    super(props);
    this.loadQueryData();
  }

  state = {
    selectedDate: moment(),
    farmer: '',
    produce: '',
    produceDB: [],
    fullDataQuery: [],
    remarks: '',
    remarksColor: 'green',
    openAlertDialog: false,
    sowing_date: moment().tz('Asia/Kuala_Lumpur').format('yyyy-MM-DD'),
    transplant_date: moment().tz('Asia/Kuala_Lumpur').format('yyyy-MM-DD'),
    harvest_date: moment().tz('Asia/Kuala_Lumpur').format('yyyy-MM-DD'),
    spdeliver_date: moment().tz('Asia/Kuala_Lumpur').format('yyyy-MM-DD')
  }

  loadQueryData = async () => {
    let fullOutput = [];
    const db = firebase.firestore();
    let snapshot = await db.collection('farmer_produce').get();

    snapshot.forEach(doc => {
      fullOutput.push(doc.data());
    });

    this.setState({ fullDataQuery: fullOutput });
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  }

  farmerChange = async (event) => {
    let farmer = event.target.value;
    let produces = [];

    const db = firebase.firestore();
    let snapshot = await db.collection('farmer_produce').where('farmer', '==', capitalize(farmer)).get();
    
    snapshot.forEach(doc => {
      produces.push(doc.data());
    })

    this.setState({ farmer, produceDB: produces, produce: '' });
  }

  produceChange = (event) => {
    this.setState({ produce: event.target.value });
  }

  loadDistinctFarmer = () => {
    let uniq_farmers = _.uniqBy(this.state.fullDataQuery, 'farmer');

    return uniq_farmers.map((item, key) => {
      return (
        <MenuItem value={item.farmer} key={key}>{item.farmer}</MenuItem>
      );
    });
  }

  loadFarmersProduce = () => {
    return this.state.produceDB.map((item, key) => {
      return (
        <MenuItem value={item.produce} key={key}>{item.produce}</MenuItem>
      )
    })
  }

  sowing_date_change = (event) => {
    this.setState({ sowing_date: event.target.value });
  }

  transplant_date_change = (event) => {
    this.setState({ transplant_date: event.target.value });
  }

  harvest_date_change = (event) => {
    this.setState({ harvest_date: event.target.value });
  }

  spdeliver_date_change = (event) => {
    this.setState({ spdeliver_date: event.target.value });
  }

  validateEntry = () => {
    const { farmer, produce, sowing_date, transplant_date, harvest_date, spdeliver_date } = this.state;
    
    if (farmer === '' || produce === '' || sowing_date === '' || transplant_date === '' || harvest_date === '' ||
      spdeliver_date === '') {
        this.setState({ remarks: 'All fields must be entered ', remarksColor: 'red' });
    } else {
      this.openDialog();
    }
  }

  openDialog = () => {
    this.setState({ openAlertDialog: true });
  }

  closeDialog = () => {
    this.setState({ openAlertDialog: false });
  }

  saveToFirestore = async () => {
    const { farmer, produce, sowing_date, transplant_date, harvest_date, spdeliver_date } = this.state;

    const db = firebase.firestore();
    await db.collection('produce_tracker').where('farmer', '==', capitalize(farmer))
      .where('produce', '==', capitalize(produce))
      .where('sowing', '==', moment(sowing_date).tz('Asia/Kuala_Lumpur').unix())
      .where('transplant', '==', moment(transplant_date).tz('Asia/Kuala_Lumpur').unix())
      .where('harvest', '==', moment(harvest_date).tz('Asia/Kuala_Lumpur').unix())
      .where('spdeliver', '==', moment(spdeliver_date).tz('Asia/Kuala_Lumpur').unix())
      .get()
      .then((item) => {
        if (item.size === 0) {
          db.collection('produce_tracker').add({
            farmer: capitalize(farmer),
            produce: capitalize(produce),
            sowing: moment(sowing_date).tz('Asia/Kuala_Lumpur').unix(),
            transplant: moment(transplant_date).tz('Asia/Kuala_Lumpur').unix(),
            harvest: moment(harvest_date).tz('Asia/Kuala_Lumpur').unix(),
            spdeliver: moment(spdeliver_date).tz('Asia/Kuala_Lumpur').unix(),
            last_update: moment().tz('Asia/Kuala_Lumpur').unix()
          })

          this.setState({ remarks: 'Batch details saved successfully', remarksColor: 'green', openAlertDialog: false });
        } else {
          this.setState({ remarks: 'This batch exist in the system', remarksColor: 'red', openAlertDialog: false });
          return;
        }
      })
  }

  render() {
    return (
      <Aux>
        <Grid container style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <Grid item style={{ padding: '0px 15px', marginTop: '20px' }} xs={8}>
            <Card raised color="#fff" style={{ borderRadius: '8px', width: '500px', marginLeft: '150px' }}>
              <CardContent style={{ padding: '30px 50px' }}>
                <Typography variant="subtitle2" style={{ marginBottom: '20px' }}>Input Batch Details</Typography>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: '35px' }}>
                  <FormControl>
                    <InputLabel id="farmer-name">Farmer</InputLabel>
                    <Select
                      labelId="farmer-name"
                      id="farmer-name-select"
                      value={this.state.farmer}
                      onChange={this.farmerChange}
                      style={{ width: '150px' }}
                    >
                      {this.loadDistinctFarmer()}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="produce-name">Produce</InputLabel>
                    <Select
                      labelId="produce-name"
                      id="produce-name-select"
                      value={this.state.produce}
                      onChange={this.produceChange}
                      style={{ width: '150px' }}
                    >
                      {this.loadFarmersProduce()}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <TextField 
                    id="date"
                    label="Sowing"
                    type="date"
                    defaultValue={this.state.sowing_date}
                    onChange={this.sowing_date_change}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="date"
                    label="Transplant"
                    type="date"
                    defaultValue={this.state.transplant_date}
                    onChange={this.transplant_date_change}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '30px 0px' }}>
                  <TextField
                    id="date"
                    label="Harvest"
                    type="date"
                    defaultValue={this.state.harvest_date}
                    onChange={this.harvest_date_change}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="date"
                    label="Sort, Pack, Deliver"
                    type="date"
                    defaultValue={this.state.spdeliver_date}
                    onChange={this.spdeliver_date_change}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Button variant="outlined" color="primary" onClick={this.validateEntry}>Save</Button>
                  <Typography variant="subtitle2" style={{ color: this.state.remarksColor, marginLeft: '30px' }}>{this.state.remarks}</Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AlertDialog 
          open={this.state.openAlertDialog}
          handleClose={this.closeDialog}
          dialogTitle="Confirmation"
          dialogContent="Confirm to save?"
          onClickCancel={this.closeDialog}
          onClickConfirm={this.saveToFirestore}
        />
      </Aux>
    );
  }
}

export default BatchDetails;
